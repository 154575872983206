@import '../../styles/variables.less';

.short-link-wrapper {
  display: flex;
  align-items: center;

  .short-link {
    color: @primary-color;
    cursor: pointer;
  }

  .disable-short-link {
    color: @primary-color;
    text-decoration-line: line-through;
    cursor: not-allowed;

    a:active,
    a:hover {
      color: @primary-color;
      text-decoration-line: line-through;
      cursor: not-allowed;
    }
  }
}

.tags-filter-wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .tag-checkbox-div {
    display: flex;
    justify-content: center;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.filter-icon {
  font-size: 16px;
  &.anticon {
    color: @primary-color;
  }
}

.filter-filled {
  color: @primary-color;
}

.ant-table.ant-table-filter-trigger {
  color: @primary-color;
}

.common-link-table {
  span.anticon.anticon-loading.anticon-spin.ant-switch-loading-icon {
    font-size: 14px;
  }

  .anticon .anticon-loading .anticon-spin .ant-switch-loading-icon {
    font-size: 300px;
  }

  .ant-table
    .ant-table-tbody
    .anticon
    .anticon-loading
    .anticon-spin
    .ant-switch-loading-icon {
    font-size: 14px;
  }
}
