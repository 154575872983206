.btn-Primary.ant-btn {
  border-radius: 6px;
  padding: 1px 25px;
}
.ant-btn > .ant-btn-loading-icon {
  margin-right: 6px;
}
.btn-small.ant-btn {
  border-radius: 6px;
  height: 30px;
  min-width: 100px;
}
.btn-secondary.ant-btn {
  border-radius: 6px;
  min-width: 100px;
  height: 30px;
  background-color: #303179;
  border-color: #303179;
  &:hover {
    background-color: #003365;
    border-color: #003365;
  }
}
.btn-secondary-xl.ant-btn {
  border-radius: 6px;
  padding: 1px 25px;
  background-color: #303179;
  border-color: #303179;
  color: white;
  &:hover {
    background-color: #003365;
    border-color: #003365;
  }
}
.btn-back.ant-btn {
  border-radius: 6px;
  padding: 1px 5px;
}
