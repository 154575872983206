.ant-card {
  .ant-card-body {
    padding: 16px 16px;
    text-align: center;
  }
  .ant-card-head {
    padding: 0 16px;
  }
  .ant-card-head-title {
    font: 700 1em Roboto, sans-serif;
  }
}
.ant-card-bordered {
  border: 1px solid #eae7dc !important;
}
.auth-card.ant-card {
  width: 30%;
}
.cardanalytics {
  width: 200px;
}
