@import '../../styles/variables.less';

.main-form-wrapper {
  height: 100vh;
  display: flex;
  color: white;

  .app-aside {
    width: 55%;
    background-color: @primary-color;
    padding: 40px 30px;
    .glassmorphism {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(15.5px);
      -webkit-backdrop-filter: blur(15.5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      padding: 20px;
    }

    .img-info {
      width: 34%;
      color: @white-color;
      font-weight: bold;
      font-size: 18px;
      font-style: italic;
      word-break: break-word;

      ul {
        margin-top: 10px;
        padding-left: 17px;
      }
      li::marker {
        font-size: 25px;
      }
      li:not(:first-child) {
        margin-top: 20px;
      }
      li {
        font-style: normal;
      }
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        padding: 0 0;
        font-size: 14px;

        li::marker {
          font-size: 12px;
        }
        li:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    .mt-80 {
      margin-top: 80px;

      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        padding: 40px 10px;
      }
    }

    .project-img {
      width: 66%;
      height: fit-content;
      img {
        width: 100%;
      }
    }
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      padding: 40px 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 0%;
      display: none;
    }
  }

  .ant-tabs-content-holder {
    min-height: 230px;
  }
  .app-form {
    width: 45%;
    background-color: white;
    padding: 0% 5%;
    overflow: auto;

    .ant-checkbox-inner {
      border-radius: unset;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .logo-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
  .logo {
    height: 70px;
    width: 50%;
  }

  .formField {
    margin-bottom: 40px;
  }

  .shorten-url-section {
    background: #f7d6d1;
    border-left: 4px solid @primary-color;
    height: 50px;
    padding: 10px;
  }

  .shorten-url-labels {
    font-size: 16px;
    font-weight: 200;
  }
}
