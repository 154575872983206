@import '../../styles/variables.less';

.folder-name {
  width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.welcome-model {
  .mt-30 {
    margin-top: 30px;
  }
  .header {
    .title-text {
      margin-top: 16px;
      font-weight: 500;
      color: @primary-color;
      font-size: 22px;
    }
  }

  .ant-modal-close {
    .anticon {
      font-size: 20px;
      color: #031429;
    }
  }

  .welcome-message {
    padding: 0px 38px;
    line-height: 26px;
    font-size: 15px;
    margin-top: 20px;
    color: #031429;

    .points {
      margin-left: 10px;
    }

    ul {
      margin-left: 33px;
    }
  }
}
