@import '../../styles/variables.less';

.meta-card-section {
  .title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .left-section {
    width: 50%;
    padding: 0px 20px;
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      left: 0;
    }
  }

  .right-section {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    .meta-card {
      width: 320px;
      border-radius: 10px;
      &.ant-card-bordered .ant-card-cover {
        border: 1px solid #eae7dc;
        border-radius: 11px 11px 0px 0px;
      }
      .ant-card-body {
        .preview-section {
          height: 100%;
          width: 100%;
          padding: 25px;
        }
        .ant-card-meta {
          .ant-card-meta-title {
            text-align: start;
            word-break: break-word;
          }
          .ant-card-meta-description {
            text-align: start;
            word-break: break-word;
          }
        }
      }
      .meta-card-section
        .right-section
        .meta-card
        .ant-card-body
        .ant-card-meta
        .ant-card-meta-description {
        word-break: break-word;
      }
    }
    .meta-img {
      height: 180px;
      object-fit: cover; //  cover is better for meta tag need to discuss with sarad sir
    }
  }
}

.border-right {
  border-right: 1px solid #e8e8e8;
}

.verify-button {
  margin-top: 28px;
}
.hide-border {
  display: none;
}

.add-link-modal.ant-modal {
  .ant-modal-body {
    .qr-code-section {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .qr-label {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}

.shorten-url-section {
  background: #f7d6d1;
  border-left: 4px solid @primary-color;
  height: 50px;
  padding: 10px;
}

.shorten-url-labels {
  font-size: 16px;
  font-weight: 200;
}

.domain-description {
  width: 250px;
}

.domain-popover {
  .ant-popover-inner-content {
    background-color: #fde6e2;
    padding: 13px 13px 1px;
  }
  &.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    border-right-color: #fde6e2;
    border-bottom-color: #fde6e2;
  }
}
